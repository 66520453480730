/* eslint-disable */

import 'virtual:uno.css';
import 'virtual:unocss-devtools';

// ===== Bootstrap 3 & DataTable components integration (JQuery needed) ======
import $ from "jquery";
// =============== Base libraries integration ==================
import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import {dom, library} from '@fortawesome/fontawesome-svg-core'
import * as icons from "./assets/icons/font-awesome-icons"
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import VueFileAgent from "vue-file-agent";
import axios from "axios";
import VueI18n from "vue-i18n";

import {i18n} from './plugins/i18n';
import YagroStateLoader from './plugins/yagro_state_loader';
import YAlertPlugin from './plugins/y-alerts';

import VeeValidate from "vee-validate";
import VueEvents from "vue-events";
import VueApollo from 'vue-apollo'
import store from "@/stores";
import initRouter from "@/vuerouter/index";
import sentry from "./sentry_init";
import {get_current_language, load_languages} from "@/languages";
import auth from '@/auth';
import {ApolloClient} from 'apollo-client'
import {createHttpLink} from 'apollo-link-http'
import {InMemoryCache} from 'apollo-cache-inmemory'
import {setContext} from 'apollo-link-context'
import {RetryLink} from "apollo-link-retry";
import {onError} from "apollo-link-error";
import {ApolloLink, fromPromise} from "apollo-link";
import PortalVue from 'portal-vue'

import "./components/directives/focus";
import "./components/directives/click-outside";

// ======================= Base Component ======================
import App from "./App";
import axios_init from "./axios_init";

window.$ = $;

library.add(icons)
dom.watch()

const apiVersion = process.env.YAGRO_VERSION;

const vvConfig = {
  errorBagName: "formErrors",
  fieldsBagName: 'veeFields',
};

VeeValidate.Validator.extend('verify_password', {
  getMessage: field => `The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`,
  validate: value => {
    var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\\W)(?=.{8,})");
    return strongRegex.test(value);
  }
});

let nullableDecimalRule = {
  getMessage(field, args, blah) {
    return `${field} value should be between ${args[0]} and ${args[1]}`
  },
  validate(value, { min, max }) {
      if (!value) {
        return true
      }
      return Number(min) < value && Number(max) > value;
  }
}

const paramNames = ['min', 'max'];
VeeValidate.Validator.extend('nullable-decimal', nullableDecimalRule, {
  paramNames
});

Vue.use(BootstrapVue);
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueFileAgent);
Vue.use(VeeValidate, vvConfig);
Vue.use(VueEvents);
Vue.use(VueI18n);
Vue.use(YagroStateLoader, store);
Vue.use(VueApollo);
Vue.use(YAlertPlugin, { store });
Vue.use(PortalVue);


const tooltip_options = {
  // Default events that trigger the tooltip
  defaultTrigger: "hover focus click"
};

// Integrate Axios into Vue
Object.defineProperty(Vue.prototype, "$http", {
  get() {
    return axios;
  },
  configurable: true
});

//Define Vue Mixin to be attached to every Vue instance created
let _yagro_uid = 0;
Vue.mixin({
    beforeCreate: function () {
      this.uid = _yagro_uid++
    },

    methods: {
      navigateTo: function (routerDestination) {
        return this.$router.push(routerDestination);
      }
    }
  }
)

//Define Vue Mixin for instance created below
const vue_mixin = {
  methods: {
    showMessage(status, title, text, timeout = null) {
      if (status === "error") {
        status = "danger"
      }

      this.$YAlert.addAlert({
        mode: status,
        title: title,
        text: text,
        autoDismiss: true,
        isDismissible: true,
        autoDismissTime: timeout ? timeout : 3000,
      });
    }
  }
};

// ======================== Create an event bus between components ====================
const bus = new Vue();
Object.defineProperty(Vue.prototype, "$bus", {
  get() {
    return bus;
  },
  configurable: true
});
let urlbase = "/gui"

// ======================== Vue Instance =======================
/* eslint-disable no-new */
store.dispatch("settings/fetchSettings").then(function () {
  let vueRouter = initRouter(store.getters["settings/features"]);

  // ======================== Apollo graphql client ===============

  // HTTP connection to the API
  const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: store.state.settings.graphqlBase + "/graphql",
  });

  // Authentication handler
  const authLink = setContext(async (_, {headers}) => {
    const token = auth.getAuthHeader()
    return {
      headers: {
        ...headers,
        authorization: token ? token : "",
      }
    }
  });

  // Retry handler
  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => !!error
    }
  });

  const errorLink = onError(
    ({graphQLErrors, networkError, operation, forward}) => {
      if (graphQLErrors) {
         try {
           for (let err of graphQLErrors) {
              if (err.message.includes("Signature has expired")) {
                    return fromPromise(
                      (auth.refresh_promise().then(() => {
                          const oldHeaders = operation.getContext().headers;
                          const token = auth.getAuthHeader();
                          operation.setContext({
                            headers: {
                              ...oldHeaders,
                              authorization: token ? token : "",
                            },
                          })
                        }).then(() => {
                          const subscriber = {
                            next: observer.next.bind(observer),
                            error: observer.error.bind(observer),
                            complete: observer.complete.bind(observer)
                          };

                          // Retry last failed request
                          forward(operation).subscribe(subscriber);
                        })
                          .catch(error => {
                            // No refresh or client token available, we force user to login
                            observer.error(error);
                          })
                      ));
                    }
            }
        }
        catch {
          console.log(graphQLErrors)
        }
    }
    });

  // Cache implementation
  const cache = new InMemoryCache()

  // Create the apollo client
  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([retryLink, errorLink, authLink, httpLink]),
    cache,
    defaultOptions: defaultOptions
  })

  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
  })

  try {
    sentry.init(vueRouter);
    i18n.locale = get_current_language();
    load_languages();
  } finally {
    window.vm = new Vue({
      i18n,
      store,
      bus,
      router: vueRouter,
      apolloProvider,
      data: {
        apiVersion,
        urlbase
      },
      el: "#app",
      beforeCreate() {
        this.$store.dispatch('posthog/initialise');
        /* Populates user data from local storage */
        this.$store.dispatch('user/initialiseTheStore');
        /* Populates table settings data from local storage */
        this.$store.dispatch('tableSettings/initialiseTheStore');
        axios_init.init(axios, apiVersion);
      },
      mixins: [vue_mixin],
      render: h => h(App),
      mounted() {
      }
    });
  }
});
