const display_state = {
  sidebar: true,
  loadingJobs: [],
};

const display_getters = {};

const display_mutations = {
  SET_SIDEBAR(state, value) {
    state.sidebar = value;
  },

  ADD_LOADING_KEY(state, payload) {
    state.loadingJobs.push({
      id: payload.key,
      totalIncrements: payload.total,
      completedIncrements: 0,
    })
  },
  PROGRESS_LOADING_KEY(state, key) {
    let matchLoadingJobIndex = state.loadingJobs.findIndex(job => job.id === key)
    state.loadingJobs[matchLoadingJobIndex].completedIncrements += 1;
    if (state.loadingJobs[matchLoadingJobIndex].completedIncrements >= state.loadingJobs[matchLoadingJobIndex].totalIncrements) {
      state.loadingJobs.splice(matchLoadingJobIndex, 1)
    }
  },
};

const display_actions = {};

const display_module = {
  namespaced: true,
  state: display_state,
  getters: display_getters,
  mutations: display_mutations,
  actions: display_actions,
};

export default display_module;
